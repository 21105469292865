<template>
  <AppNav>Welcome Pack 이벤트</AppNav>
  <div class="wrapper">
    <section class="header">
      <img
        class="text"
        src="https://res.tutoring.co.kr/res/images/evt/com/welcome/img_tl_top.png"
        alt="Welcome pack event"
      />
      <img
        class="month"
        :src="`https://res.tutoring.co.kr/res/images/evt/com/welcome/img_${currentMonth[1]}.png`"
        :alt="`${currentMonth[0]}월`"
      />
    </section>
    <section class="content">
      <h2 class="title">
        튜터링 가입하고
        <br />
        <span class="point">네이버페이</span>
        받자!
      </h2>
      <img
        class="card"
        src="https://res.tutoring.co.kr/res/images/evt/com/welcome/img_naverpay_20000.png"
        alt="네이버페이 쿠폰 이미지"
      />
    </section>
    <section class="info">
      <div class="block">
        <strong class="title">이벤트 대상</strong>
        <p class="detail">
          {{ currentMonth[0] }}월 튜터링 신규 가입자 중
          <br />
          <strong>첫 수업</strong>
          을 진행한 회원 50명
        </p>
      </div>
      <div class="block">
        <strong class="title">이벤트 선물</strong>
        <p class="detail">
          네이버페이 20,000원
          <br />
          <small>(당첨자 50명, 기프티콘으로 발송 예정)</small>
        </p>
        <img class="sum" src="https://res.tutoring.co.kr/res/images/evt/com/welcome/img_sum.png" alt="" />
        <p class="detail">
          튜터링 첫 수업 수강권
          <br />
          <small>(가입과 동시 전원 자동 지급)</small>
        </p>
      </div>
      <div class="block">
        <strong class="title">당첨자 선정</strong>
        <p class="detail">
          이벤트 참여자들 중
          <br />
          <strong>랜덤 추첨</strong>
          을 통해 당첨자 선정
        </p>
      </div>
      <div class="block">
        <strong class="title">당첨자 공지</strong>
        <p class="detail">
          이벤트 종료 후 매월 10일
          <br />
          튜터링 공식 인스타그램 계정에 공지
          <br />
          ( @tutoring_en )
        </p>
      </div>
    </section>
    <section class="howto">
      <strong class="title">참여 방법</strong>
      <p class="step">
        <span class="badge">STEP 1</span>
        <span class="text">튜터링에 가입을 한다.</span>
      </p>
      <p class="step">
        <span class="badge">STEP 2</span>
        <span class="text">
          무료로 지급된 수강권으로
          <br />
          첫 수업을 진행한다.
        </span>
      </p>
      <p class="step">
        <span class="badge">STEP 3</span>
        <span class="text">
          이벤트 대상 중, 랜덤 추첨을 통해
          <br />
          경품 기프티콘으로 발송
        </span>
      </p>
    </section>
    <section class="button">
      <button @click="goFirstLesson">첫 수업 하러 가기</button>
    </section>
    <aside class="noti">
      <strong class="title">아래의 내용을 꼭 확인해 주세요!</strong>
      <ul>
        <li>
          본 이벤트 주최사 사정으로 인해 이벤트 경품, 지급 방법 변경, 당첨자 발표 일자 및 이벤트 조기종료될 수 있습니다.
        </li>
        <li>이벤트 경품 변경 시, 동일 가격의 상품으로 대체됩니다.</li>
        <li>
          경품 발송을 위해 개인정보 수집에 대한 요청이 발생될 수 있으며, 거부 시 경품 발송 등이 제한될 수 있습니다.
        </li>
        <li>
          당첨자 발표는 이벤트 종료 후 튜터링 공식 인스타그램에 공지 및 개별 안내될 예정이며, 당첨 발표 안내 후 5일
          이내에 답변이 없을 시 혹은 응모한 개인정보 오류 시 당첨이 자동 취소됩니다.
        </li>
      </ul>
      <strong class="sub-title">충전권 이용안내</strong>
      <ul>
        <li>
          네이버페이 포인트는 네이버쇼핑/네이버웹툰/네이버예약을 비롯한 45만 개 이상 가맹점에서 현금처럼 이용하실 수
          있습니다.
        </li>
      </ul>
      <strong class="sub-title">사용방법</strong>
      <ul>
        <li>모바일 : 네이버페이 > 우상단 삼선메뉴(三)클릭 > 쿠폰 > '기프티쇼 쿠폰 등록'</li>
        <li>PC : 네이버페이 > 혜택/쿠폰 > 기프티쇼 쿠폰등록</li>
      </ul>
      <strong class="sub-title">네이버페이 포인트 이용 가이드</strong>
      <ul>
        <li>포인트는 1인당 최대 200만 원까지 보유하실 수 있습니다.</li>
        <li>
          쿠폰이 정상적으로 등록된 경우, 네이버페이 > 포인트 메뉴에서 내역을 확인하실 수 있습니다. (반영에 최대 1분이
          소요됩니다)
        </li>
        <li>쿠폰으로 등록된 금액은 적립 포인트로 지급되며, 취소 및 현금 인출이 불가합니다.</li>
        <li>일부 페이 가맹점에서는 포인트 사용이 제한될 수 있습니다.</li>
      </ul>
    </aside>
  </div>
</template>

<script>
import { storage, native } from '@/tools';
import AppNav from '../../components/AppNav.vue';

export default {
  name: 'WelcomePackEvent',
  components: {
    AppNav,
  },
  computed: {
    currentMonth() {
      const month = new Date().getMonth() + 1;
      const monthWithPadding = month < 10 ? `0${month}` : `${month}`;
      return [month, monthWithPadding];
    },
  },
  methods: {
    goFirstLesson() {
      storage.session.set('routeAfterWebViewClosed', '/app/home/lms');
      native.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  overflow: hidden;
}

.header {
  position: relative;
  width: 100%;
  height: 91.67vw;
  margin-top: 38.889vw;
  background-color: #00c035;
  .text {
    width: 100%;
    margin-top: -22.2223vw;
  }
  .month {
    position: absolute;
    right: 27px;
    bottom: 30px;
    width: 28.93%;
  }
}

.content {
  margin-top: 84px;
  .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: -1px;
    color: #121214;
    text-align: center;
    .point {
      color: #00c035;
    }
  }
  .card {
    width: 100%;
    display: block;
    margin: 3% 6% 0;
  }
}

.info {
  .block {
    margin: 0 20px;
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid rgba(63, 63, 63, 0.1);
  }
  .title {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1px;
    color: #000000;
    margin-bottom: 15px;
  }
  .detail {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #85808d;
    strong {
      color: #000000;
      font-weight: 700;
      margin-right: -1.6px;
    }
    small {
      font-size: 12px;
    }
  }
  .sum {
    width: 30px;
    height: 30px;
    margin: 10px 0;
  }
}

.howto {
  margin-top: 50px;
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1px;
    color: #000000;
  }
  .step {
    margin-top: 30px;
  }
  .badge {
    display: inline-block;
    font-weight: 900;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    padding: 9px 16px;
    background-color: #00c035;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .text {
    display: block;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #85808d;
  }
}

.button {
  margin-top: 50px;
  padding: 0 80px;
  button {
    width: 100%;
    height: 60px;
    background: #5d04d9;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
  }
}

.noti {
  margin-top: 80px;
  margin-bottom: 60px;
  .title {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #f24b4b;
    &::before {
      content: '';
      width: 18px;
      height: 18px;
      background-image: url('https://res.tutoring.co.kr/res/images/evt/com/welcome/ic_cation.png');
      background-size: cover;
      margin-right: 6px;
    }
  }
  .sub-title {
    display: block;
    padding-left: 30px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #5e5a66;
  }
  ul {
    padding: 0 30px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #85808d;
    li {
      position: relative;
      padding-left: 17px;
      &::before {
        content: '•';
        position: absolute;
        left: 0;
        width: 17px;
        text-align: center;
      }
    }
  }
}
</style>
